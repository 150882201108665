import * as React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { SiteMetadata } from '../interfaces/siteMetadata'

interface Props {
  en: boolean
  siteMetadata: SiteMetadata
}

export const MiniBio = ({ en, siteMetadata }: Props) => {
  const author = siteMetadata?.author

  return (
    <Wrapper>
      <StaticImage
        layout="fixed"
        src="../images/profile-pic.jpg"
        width={47}
        height={47}
        quality={100}
        alt="Profile picture"
      />
      <p dangerouslySetInnerHTML={{ __html: en ? author?.summary.en : author?.summary.ja }} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 47px;
  margin: 90px 0;

  color: ${({ theme }) => theme.grey};
  font-size: ${({ theme }) => theme.font.xs};

  div {
    box-shadow: none;
    margin-right: 20px;
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
  }

  img {
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
  }
`
