import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { RelatedPost } from '../interfaces/markdownRemark'

interface Props {
  posts: RelatedPost[] | []
}

export const RelatedPosts = ({ posts }: Props) => {
  return (
    <Wrapper>
      <Line>
        <span>Related articles</span>
        <hr />
      </Line>
      <Posts>
        {posts.slice(0, 2).map((p: RelatedPost) => (
          <Card key={p.frontmatter.title} to={p.fields.slug}>
            <div>
              <GatsbyImage alt={'cover'} image={getImage(p.frontmatter.cover) as any} />
            </div>
            <h4>{p.frontmatter.title}</h4>
          </Card>
        ))}
      </Posts>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;

  span {
    min-width: max-content;
    margin-right: 20px;
    color: ${({ theme }) => theme.grey};
  }

  hr {
    width: 100%;
  }
`

const Posts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a:only-child {
    margin: 0 auto;
  }
`

const Card = styled(Link)`
  width: 45%;

  > div {
    width: 100%;
  }

  h4 {
    padding-top: 8px;
    font-weight: 600;
    font-family: ${({ theme }) => theme.font.serif};
    font-size: min(${({ theme }) => theme.font.h4}, 3vw);
  }
`
