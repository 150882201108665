import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { MiniBio } from '../components/miniBio'
import { Layout } from '../components/layout'
import Seo from '../components/seo'
import { Center } from '../components/layout'
import { RelatedPosts } from '../components/relatedPosts'
import { Adsense } from '../components/googleAdsense'

import { useSiteMetadata } from '../hooks/useSiteMetadata'
import { MarkdownRemark } from '../interfaces/markdownRemark'
import { Location } from '../interfaces/gatsby'

interface Props {
  data: Data
  location: Location
}

interface Data {
  markdownRemark: MarkdownRemark
  previous: any
  next: any
}

const BlogPostTemplate = ({ data, location }: Props) => {
  const en = location.pathname.split('/')[1] === 'en'
  const { previous, next, markdownRemark } = data
  const post = markdownRemark
  const siteMetadata = useSiteMetadata()

  return (
    <Layout en={en} location={location} siteMetadata={siteMetadata} article={true}>
      <Seo
        lang={en ? 'en' : 'ja'}
        localTitle={post.frontmatter.title}
        localDescription={post.frontmatter.description || post.excerpt}
        keywords={post.frontmatter.tags}
        socialImage={siteMetadata.siteUrl + post.frontmatter.cover.publicURL}
        socialUrl={siteMetadata.siteUrl + post.fields.slug}
        article={true}
      />
      <Center>
        <Article className="blog-post" itemScope itemType="http://schema.org/Article">
          <Top>
            <H1 itemProp="headline">{post.frontmatter.title}</H1>
            <div>
              <p>{post.frontmatter.date}</p>{' '}
              <Link
                to={
                  post.frontmatter.language === 'en'
                    ? `/en/category/${post.frontmatter.category}`
                    : `/category/${post.frontmatter.category}`
                }
              >
                &#9733; {post.frontmatter.category}
              </Link>
            </div>
            <Photo
              image={getImage(post.frontmatter.cover)}
              fluid={post.frontmatter.cover.childImageSharp.fluid}
              alt={'article cover'}
              objectFit={'cover'}
              quality={100}
              style={{ marginTop: '30px', maxHeight: '600px', borderRadius: '5px' }}
            />
            {post.frontmatter.photoBy ? (
              <PhotoTip>
                Image by{' '}
                <a href={post.frontmatter.photographerUrl} target={'_blank'} rel={'noreferrer'}>
                  <u>{post.frontmatter.photoBy}</u>
                </a>
              </PhotoTip>
            ) : null}
          </Top>
          <Section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
            lang={post.frontmatter.language}
          />
          <Adsense style={{ marginBottom: '60px' }} />
          {post.relatedPosts.length > 0 ? <RelatedPosts posts={post.relatedPosts} /> : null}
          <hr />
          <Bottom>
            <MiniBio en={en} siteMetadata={siteMetadata} />
          </Bottom>
        </Article>
        <Nav className="blog-post-nav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <>
                  <p>← </p>
                  <Link to={previous.fields.slug} rel="prev">
                    <span className={'long'}>{previous.frontmatter.title}</span>
                    <span className={'short'}>NEW</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {next && (
                <>
                  <Link to={next.fields.slug} rel="next">
                    <span className={'long'}>{next.frontmatter.title}</span>
                    <span className={'short'}>OLD</span>
                  </Link>
                  <p> →</p>
                </>
              )}
            </li>
          </ul>
        </Nav>
      </Center>
    </Layout>
  )
}

export default BlogPostTemplate

const Top = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 60px;

  > div {
    display: flex;
    justify-content: center;

    > a {
      margin-left: 20px;
      color: ${({ theme }) => theme.gold};
    }
  }
`

const H1 = styled.h1`
  font-family: ${({ theme }) => theme.font.serif};
  font-weight: 600;
`

const Photo = styled(GatsbyImage)<any>``

const PhotoTip = styled.span`
  position: absolute;
  right: 5px;
  bottom: -20px;
  font-size: 0.7rem;
  color: ${({ theme }) => theme.lightGrey};
`

const Article = styled.article`
  max-width: 1000px;
  margin: 50px auto 0 auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.small}) {
    margin: 10px auto 0 auto;
  }
`

const Section = styled.section<{ lang: string }>`
  font-size: 1.1rem;
  margin-bottom: 100px;

  :only-child {
    margin: 0;
    padding: 0;
  }

  em {
    border-bottom: 3px solid #ccc;
  }

  h2 {
    font-family: ${({ theme }) => theme.font.serif};
    font-weight: 600;
  }

  pre {
    //margin: 0 80px 40px 40px;
    padding: 20px;
  }

  pre,
  > p,
  > ol,
  > ul,
  > blockquote {
    margin: 0 80px 40px 80px;
  }

  > table {
    margin: 0 auto 40px auto;
    border: 2px solid #505050;

    th,
    td {
      padding: 4px 12px;
      border-right: 1px #ddd solid;
    }

    th {
      font-weight: bold;
    }

    tr {
      border-bottom: 1px #ddd solid;
    }
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 70px 80px 20px 80px;
  }

  ul {
    list-style: inside;
  }

  blockquote {
    padding: 10px 15px;
    border-left: 4px solid grey;
    font-style: italic;
    font-weight: bolder;
  }

  a {
    color: ${({ theme }) => theme.accentMagenta};
  }

  p {
    font-size: ${({ lang }) => (lang === 'en' ? '1.17rem' : '100%')};
    line-height: ${({ lang }) => (lang === 'en' ? '1.6' : '1.8')};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.small}) {
    margin-bottom: 70px;

    pre {
      padding: 10px;
    }

    pre,
    > p,
    > ol,
    > ul,
    > blockquote {
      margin: 20px 10px;
    }

    > table {
      margin: 20px auto;
    }

    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      margin: 40px 10px 20px 10px;
    }
  }

  .twoImagesSideBySide {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    margin-bottom: 40px;

    > span {
      width: 100%;
    }
  }
`

const Nav = styled.nav`
  li {
    display: flex;
    align-items: center;
    max-width: 40vw;
  }

  p {
    margin: 0 10px;
  }

  span.short {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.small}) {
    span.short {
      display: inherit;
    }

    span.long {
      display: none;
    }
  }
`

const Bottom = styled.div``

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        category
        language
        tags
        cover {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              placeholder: BLURRED
              webpOptions: { quality: 95 }
              width: 1000
              quality: 95
            )
          }
          publicURL
        }
        photoBy
        photographerUrl
      }
      relatedPosts {
        frontmatter {
          title
          cover {
            childImageSharp {
              gatsbyImageData(
                quality: 95
                placeholder: BLURRED
                webpOptions: { quality: 95 }
                width: 450
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        fields {
          slug
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
