import React, { useEffect, useState } from 'react'

interface Props {
  style?: object
}

export const Adsense = ({ style }: Props) => {
  let timer: NodeJS.Timer
  let innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0
  const [resizing, setResizing] = useState(false)

  const turnOnAd = () => {
    // @ts-ignore
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }

  const handleResize = () => {
    if (innerWidth === window.innerWidth) return null
    innerWidth = window.innerWidth

    setResizing(true)

    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      setResizing(false)
      turnOnAd()
    }, 300)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      turnOnAd()

      window.addEventListener('resize', handleResize)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
      clearTimeout(timer)
    }
  }, [])

  if (resizing) return <div />

  return (
    <div style={style}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-7251245251496413"
        data-ad-slot="2630659233"
        data-ad-format="auto"
        data-adtest="on"
        data-full-width-responsive="true"
      />
    </div>
  )
}
